<!-- 智慧服务/财务管理/薪酬管理-薪酬查询 -->
<template>
    <div class="meeting-info">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <div class="filter-wrap">
                    <expand-filter
                        :formData="formData"
                        marginBottom="0px"
                        @clickBtn="clickBtn"
                        @changeBtnFormType="changeBtnFormType"
                        :closeWidth="340"
                    ></expand-filter>
                    <div class="tip">单位：元</div>
                </div>

                <table-data
                    :config="table_config"
                    :tableData="table_data"
                    v-loading="loadingTable"
                    backgroundHeader="#FAFBFC"
                    :headerStyle="{height: '50px'}"
                    :rowStyle="{height: '50px'}"
                    ref="table"
                    id="table"
                >
                    <template #projectName="{data}">
                        <span
                            style="color: #3C7FFF; cursor: pointer"
                            @click="handleDetail(data)"
                            >{{ data.projectName }}</span
                        >
                    </template>
                    <template v-slot:salary="slotData">
                        <div
                            class="setIcon"
                            @click="hiddenPrice()"
                        >
                            <img
                                class="img"
                                v-if="PriceState"
                                :src="showImg"
                                alt=""
                            />
                            <img
                                class="img"
                                v-else
                                :src="hiddenImg"
                                alt=""
                            />
                            <span>薪资</span>
                        </div>
                    </template>
                    <template #salary1="{data}">
                        <span v-show="PriceState">{{ data.salary || 0 }}</span>
                        <span v-show="!PriceState">{{ "****" }}</span>
                    </template>
                </table-data>
                <pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getTableList"
                />
                <!-- 薪酬详情 -->
                <dialog-wrapper
                    :dialogObj="dialogObj"
                    @handleClose="handleClose"
                    class="boxDialog"
                >
                    <div class="box">
                        <div class="box_title">{{ detailData.title }}</div>
                        <div class="box_money">
                            <div class="tip">单位：元</div>
                        </div>
                        <div
                            v-for="(item, index) in detailData.list"
                            :key="index"
                            class="item_list"
                        >
                            <div class="name">{{ item.typeName }}</div>
                            <div class="money">{{ item.typeValue }}</div>
                        </div>
                        <div style="text-align: right; margin-top: 28px">
                            <el-button
                                @click="handleClose()"
                                type="primary"
                                >确定</el-button
                            >
                        </div>
                    </div>
                </dialog-wrapper>
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
    </div>
</template>

<script>
import {
    FilterData,
    Loading,
    Pagination,
    DialogWrapper,
} from "common-local";
import TableData from "@/components/scrollWrapper/Sub/TableData"
import ExpandFilter from "./Sub/ExpandFilter.vue";
import selectTree from "./Sub/selectTree";
import {SalaryProjectModel} from "@/models/SalaryProject.js";
import onResize from "@/mixins/onResize"
// 逻辑运算
export default {
    mixins: [onResize],
    name: "SServiceMoneySalaryQueryScrollWrapper",
    components: {
        selectTree,
        FilterData,
        Loading,
        TableData,
        Pagination,
        ExpandFilter,
        DialogWrapper,
    },
    props: {
        routerReturn: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            total: 0,
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                schoolId: "",
                startTime: "",
                endTime: "",
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "datePick",
                        label: "",
                        key: "dateArr",
                        value: [],
                        dateType: "monthrange",
                        format: "yyyy-MM",
                        valueFormat: "yyyy-MM-dd",
                        startPlaceholder: "请选择开始日期",
                        endPlaceholder: "请选择结束日期",
                        defaultTime: ["00:00:00", "23:59:59"],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["query:list"],
                    },
                ],
            },
            table_config: {
                thead: [
                    {
                        label: "项目名称",
                        type: "slot",
                        slotName: "projectName",
                        fixed: false,
                        align: "center",
                    },
                    {
                        label: "薪资",
                        type: "setHeader",
                        slotName1: "salary1",
                        slotName: "salary",
                        fixed: false,
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "所属月份",
                        prop: "months",
                        align: "center",
                    },
                    {
                        label: "发放日期",
                        prop: "sendTimes",
                        align: "center",
                    },
                ],
                check: false,
                showIndex: false,
                height: "",
            },
            table_data: [],
            dialogObj: {
                title: "薪酬详情",
                dialogVisible: false,
                width: "400px",
            },
            detailData: {
                title: "",
                list: [],
            },
            PriceState: false, //   薪资状态
        };
    },
    created() {
        this.getList();
    },
    computed: {
        showImg() {
            return require("@/assets/images/show.jpg");
        },
        hiddenImg() {
            return require("@/assets/images/hidden.jpg");
        },
    },
    mounted() {
    },
    methods: {
        /**
         * @Description: 获取列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:23:24
         */
        getList(isPaging) {
            this.loadingTable = true;
            let data = {};
            if (!isPaging) {
                this.listQuery.pageNum = 1;
            }
            this.formData.data.forEach((item) => {
                data[item.key] = item.value;
            });
            if (data.dateArr && data.dateArr.length > 0) {
                this.listQuery.startTime = data.dateArr[0] + " 00:00:00";
                this.listQuery.endTime = data.dateArr[1] + " 23:59:59";
            } else {
                this.listQuery.startTime = "";
                this.listQuery.endTime = "";
            }
            this.listQuery.schoolId = this.$store.state.schoolId;
            const salaryProjectModel = new SalaryProjectModel();
            salaryProjectModel.getQueryTeacherSalaryList(this.listQuery).then((res) => {
                if (res.data.code === "200") {
                    this.table_data = res.data.data.list;
                    this.total = res.data.data.totalCount;
                    //修改列表时间的格式
                    if (this.table_data.length > 0) {
                        this.table_data.map((i) => {
                            console.log(i, "0000000000000000");
                            //修改发放时间格式
                            if (i.sendTime) {
                                let sendTimes = this.computedTime(
                                    i.sendTime,
                                );
                                this.$set(i, "sendTimes", sendTimes);
                            }
                            //修改所属月份格式
                            if (i.month) {
                                let months = this.dealWithMonth(i.month);
                                this.$set(i, "months", months);
                            }
                            //处理数据，获取薪资
                            if (i.typeList.length > 0) {
                                i.typeList.map((item) => {
                                    let val = "实发工资";
                                    if (item.typeName === val) {
                                        let salary = item.typeValue;
                                        this.$set(i, "salary", salary);
                                    }
                                });
                            }
                        });
                    }

                    this.$nextTick(() => {
                        this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                    });
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
                this.loadingTable = false;
            }).catch(err => {
                this.loadingTable = false;
            })
        },
        /**
         * @Description: 处理时间格式
         * @DoWhat:当年只显示月日时分，非当年显示年月日时分
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-05 16:09:53
         */
        computedTime(Time) {
            var dateObj = new Date(Time);
            var year = dateObj.getFullYear();
            var month =
                dateObj.getMonth() + 1 < 10
                    ? "0" + (dateObj.getMonth() + 1)
                    : dateObj.getMonth() + 1;
            var day =
                dateObj.getDate() < 10
                    ? "0" + dateObj.getDate()
                    : dateObj.getDate();
            var hour =
                dateObj.getHours() < 10
                    ? "0" + dateObj.getHours()
                    : dateObj.getHours();
            var minute =
                dateObj.getMinutes() < 10
                    ? "0" + dateObj.getMinutes()
                    : dateObj.getMinutes();
            var now = new Date();
            var now_new = now.getFullYear(); //年
            if (year == now_new) {
                return month + "-" + day + " " + hour + ":" + minute;
            } else {
                return (
                    year + "-" + month + "-" + day + " " + hour + ":" + minute
                );
            }
        },
        /**
         * @Description: 处理月份格式
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-05 16:17:35
         */
        dealWithMonth(month) {
            let arr = month.split("-");
            return arr[0] + "年" + arr[1] + "月";
        },
        /**
         * @Description: 查询、新增类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:19:28
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 薪资显示、隐藏
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-28 15:00:38
         */
        hiddenPrice() {
            this.PriceState = !this.PriceState;
        },
        /**
         * @Description: 新增详情
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:19:08
         */
        handleDetail(data) {
            this.detailData.title = data.projectName;
            this.detailData.list = data.typeList;
            this.dialogObj.dialogVisible = true;
        },
        /**
         * @Description: 关闭、取消新增类型弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:25:34
         */
        handleClose() {
            this.dialogObj.dialogVisible = false;
        },
        /**
         * @Description: 分页处理
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-11 17:18:38
         */
        getTableList(lime) {
            console.log(lime, "lime");
            this.listQuery.pageNum = lime.page;
            this.listQuery.pageRow = lime.limit;
            this.getList(1);
        },
    },
};
</script>

<style lang="scss" scoped>
.meeting-info{
    padding-right: 10px;
}
.filter-wrap {
    display: flex;
    background-color: #fff;
    padding: 10px 20px 0;
    overflow: hidden;
    margin: 8px 0 10px 0;
	border-radius: 4px 0 0 4px;
    .filter-right-button {
        border-color: transparent;
    }

    .expand-filter {
        padding: 0;

        .date-picker-wrap {
            display: flex;
            align-items: center;

            .date-icon {
                margin-left: 10px;
                user-select: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 14px;
                height: 14px;
                background: #d4d6d9;
                border-radius: 50%;
                color: #ffffff;
                font-size: 12px;
                cursor: pointer;
            }
        }
    }
    .tip {
        line-height: 32px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6d7073;
    }
}
.setIcon {
    display: flex;
    align-items: center;
    .img {
        width: 14px;
        height: 12px;
        margin-right: 7px;
        float: none;
    }
}
.box {
    padding: 0px 8px;
    .box_title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2f33;
        padding-bottom: 15px;
    }
    .box_money {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        padding-bottom: 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #edf0f2;
        .tip {
            color: #6d7073;
        }
    }
    .item_list {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        display: flex;
        line-height: 50px;
        justify-content: space-between;
        border-bottom: 1px solid #edf0f2;
        .name {
            color: #2b2f33;
        }
        .money {
            color: #6d7073;
        }
    }
}
.boxDialog {
    /deep/.el-dialog {
        // height: 600px;
        max-height: 600px;
    }
}
</style>
