<template>
	<div class="containor">
		<tabbed-page
            :tabList="tabList"
            @changeTabs="changeTabs"
        >
        </tabbed-page>
		<div class="page-box">
			<component :is="comps[idx]" @switchClick="switchClick" @checkingInIdChange="checkingInIdChange"
				@routerReturnChange="routerReturnChange" :checkingInId="checkingInId" :routerReturn="routerReturn">
			</component>
		</div>
	</div>
</template>
<script>
import TabbedPage from './Sub/TabbedPage/index.vue'
import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';
export default {
	name: "SServiceMoneySalaryScrollWrapper",
	components: {
        TabbedPage,
		SalaryAnalysis : () => import(/* webpackChunkName:'SalaryAnalysis' */"./SServiceMoneySalary/SalaryAnalysis.vue"),
		TrendAnalysis : () => import(/* webpackChunkName:'TrendAnalysis' */"./SServiceMoneySalary/TrendAnalysis.vue"),
	},
	data() {
		return {
			currentComponent: "SalaryAnalysis",
			checkingInId: undefined,
			routerReturn: undefined,
			comps: [
                'SalaryAnalysis',
                'TrendAnalysis',
            ],
			tabList: [
				{
					id: 0,
					title: '薪酬分析',
					com: 'SalaryAnalysis',
                    vHasPermi: ['salaryAnalysis:salary'],
				},
				{
					id: 1,
					title: '趋势分析',
					com: 'TrendAnalysis',
                    vHasPermi: ['salaryAnalysis:trend'],
				},
			],
			idx: 0
		};
	},
    created () {
        this.init();
    },
	methods:{
        init () {
            this.tabList = tabPageHasAuth(this.tabList);
            this.comps = this.tabList.map(item => item.com);
            console.log(this.comps, this.tabList, 'this.tabList')
        },
		/**
		 * @Description: 切换tab
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 武东超
		 * @Date: 2023-08-16 10:01:06
		 */
		changeTabs (data) {
			this.tabList.forEach((item, index) => {
				if (item.id === data.id) {
					this.$set(item, 'sel', true)
					this.idx = index;
				} else {
					this.$set(item, 'sel', false)
				}
			})
		},
		switchClick(page) {
			this.currentComponent = page
		},
		checkingInIdChange(id) {
			this.checkingInId = id
		},
		routerReturnChange(path) {
			this.routerReturn = path
		}
	}
};
</script>
<style scoped lang="scss">
::v-deep{
	.containor{
		background-color: #edf0f7 !important;
	}
	.salary_analysis{
		margin-top: 0 !important;

		.filter-wrap{
			border-radius: 0 !important;
			margin-bottom: 1px;
		}
		.statistics{
			border-radius: 0 !important;
			margin-top: 0 !important;
			margin-bottom: 0 !important;
		}
	}
}

.tabs-list {
	height: 48px;
	display: flex;
	padding: 0 30px;
	line-height: 48px;
	align-items: center;
	margin-bottom: 10px;
	background-color: #FFFFFF;
	border-radius: 4px 0px 0px 4px;

	.switch-item {
		color: #333333;
		cursor: pointer;
		font-size: 14px;
		margin-right: 48px;
	}

	.current-item {
		position: relative;
		color: #3C7FFF;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 2px;
			background-color: #3C7FFF;
			border-radius: 1px;
		}
	}
}

.page-box {
	margin-right: 10px;
}
</style>
